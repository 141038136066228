import { Center, Loader, Select, Text, TextInput } from "@mantine/core";
import { ForwardedRef, useEffect, useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import {
  useGetApiImpactFrameworkGetImpactFrameworkById,
  useGetApiImpactFrameworkGetImpactFrameworkSetList,
} from "../../api/endpoints";
import { Step, StepResult } from "../../api/model";
import { UploadList } from "../../components/UploadList";
import Uploader from "../../components/Uploader";
import Creator from "../../components/calls/text/Creator";
import Filler from "../../components/calls/text/Filler";
import MiniDYI from "../../components/mini DYI/MiniDYI";
import AccordionOutcomes from "../admin/AccordionOutcomes";
import Survey from "../survey/survey";
import "survey-creator-core/survey-creator-core.i18n";
import { editorLocalization } from "survey-creator-core";
editorLocalization.currentLocale = "it";

export type creationComponentProps = {
  content?: string;
  setData: (value: string) => void;
};
export type FillableComponentProps = {
  step: Step;
  data?: string;
  setData: (value: string) => Promise<StepResult>;
  currentStepRef?: ForwardedRef<any>;
  readOnly?: boolean;
};

export type creationComponent = React.FC<creationComponentProps>;

export type fillableComponent = React.FC<FillableComponentProps>;

interface IStepsConfig {
  [key: string]: {
    CreationComponent: creationComponent;
    FillableComponent: fillableComponent;
  };
}

const stepsConfig: IStepsConfig = {
  text: {
    CreationComponent: Creator,
    FillableComponent: Filler,
  },
  upload: {
    CreationComponent: Uploader,
    FillableComponent: UploadList,
  },
  miniDyi: {
    CreationComponent: ({ content = "", setData }) => {
      const [selected, setSelected] = useState();
      const { data: frameworks }: any =
        useGetApiImpactFrameworkGetImpactFrameworkSetList();
      const { data: item } = useGetApiImpactFrameworkGetImpactFrameworkById({
        ImpactFrameworkId: selected,
      });

      useEffect(() => {
        setData(JSON.stringify(selected));
      }, [selected]);

      useEffect(() => {
        if (content != "") {
          setSelected(JSON.parse(content));
        }
      }, []);

      if (!frameworks) {
        <Center>
          <Loader />
        </Center>;
      }
      return (
        <>
          <Select
            value={selected}
            onChange={(e: any) => {
              setSelected(e);
              setData(e);
              console.log("e", e);
            }}
            data={frameworks?.map((e: any) => ({ value: e.id, label: e.name }))}
            placeholder={"Framework"}
          />
          {item && <AccordionOutcomes framework={item} />}
        </>
      );
    },
    FillableComponent: ({ step, setData, currentStepRef, readOnly }) => {
      const SetDataIfNotReadOnly = (value: string) => {
        if (!readOnly) {
          return setData(value);
        }
        return Promise.resolve({} as StepResult);
      }
      
      return <MiniDYI
          step={step}
          setData={SetDataIfNotReadOnly}
          impactFrameworkId={step.content}
          ref={currentStepRef}
      ></MiniDYI>
    },
  },
  results: {
    CreationComponent: ({ content = "", setData = () => null }) => {
      return (
        <TextInput value={content} onChange={(e) => setData(e.target.value)} />
      );
    },
    FillableComponent: ({ step }) => <Text size="lg">{step.content}</Text>,
  },
  survey: {
    CreationComponent: ({ content, setData }) => {
      const creatorOptions = {
        showLogicTab: true,
        isAutoSave: true,
        haveCommercialLicense: true,
      };
      const creator = new SurveyCreator(creatorOptions);
      //@ts-ignore
      useEffect(() => {
        creator.text = content!;
      }, []);
      creator.saveSurveyFunc = (saveNo: any, callback: any) => {
        setData(creator.text);
        callback(saveNo, true);
      };
      return (
        <>
          <SurveyCreatorComponent creator={creator}></SurveyCreatorComponent>
        </>
      );
    },
    FillableComponent: ({ step, setData, currentStepRef, readOnly }) => {
      const SetDataIfNotReadOnly = (value: string) => {
        if (!readOnly) {
          return setData(value);
        }
        return Promise.resolve({} as StepResult);
      }
      return <Survey
          model={step.content!}
          setData={SetDataIfNotReadOnly}
          step={step}
          ref={currentStepRef}
          readOnly={readOnly}
      ></Survey>
    },
  },
  quiz: {
    CreationComponent: ({ content, setData }) => {
      const creatorOptions = {
        showLogicTab: false,
        isAutoSave: true,
        haveCommercialLicense: true,
      };
      const creator = new SurveyCreator(creatorOptions);
      creator.text = content!;
      creator.saveSurveyFunc = (saveNo: any, callback: any) => {
        setData(creator.text);
        callback(saveNo, true);
      };
      return (
        <>
          <SurveyCreatorComponent creator={creator}></SurveyCreatorComponent>
        </>
      );
    },
    FillableComponent: ({ step, setData, currentStepRef, readOnly }) => {
      const SetDataIfNotReadOnly = (value: string) => {
        if (!readOnly) {
          return setData(value);
        }
        return Promise.resolve({} as StepResult);
      }
      return <Survey
          model={step.content!}
          setData={SetDataIfNotReadOnly}
          step={step}
          isQuiz={true}
          ref={currentStepRef}
          readOnly={readOnly}
      ></Survey>
    },
  },
};
export default stepsConfig;
